import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../store';
import {
  mapGetters
} from "vuex";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "StudentCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      studentData: {},
      gradeFeeData: {},
      parentData: {},
      student_image: '',
      // c_student_no: '',
      campus_id: '',
      entry_no: '',
      gradeArr: [],
      grade: '',
      religion: '',
      section: '',
      religionArr: null,
      sectionArr: [{
        id: 0,
        text: 'Choose Section'
      }],
      selectedSection: 0,
      selectedGrade: 0,
      selectedReligion: 0,
      submitted: false,
      isLoading: false,
      disabled: false,
      existingParent: false,
      noSectionMsg: '',

      url: '/class/create?rd_back=student',
      section_exist: '',

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoadingWebcam: false,
      link: '#'
    };
  },

  validations: {
    student_image: {},
    grade: {
      required
    },
    religion: {
      required
    },
    entry_no: {
      maxLength: maxLength(20)
    },
    section: {
      required
    },
    studentData: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100)
      },
      gender: {
        required
      },
      nationality: {
        maxLength: maxLength(100)
      },
      weight: {
        maxLength: maxLength(20)
      },
      height: {
        maxLength: maxLength(20)
      },
      sibling: {
        numeric,
        maxLength: maxLength(2)
      },
      hobby: {
        maxLength: maxLength(1000)
      },
      allergic_food: {
        maxLength: maxLength(1000)
      },
      special_topic: {
        maxLength: maxLength(1000)
      },
      blood: {
        maxLength: maxLength(20)
      },
      previous_school: {
        maxLength: maxLength(200)
      }
    },
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoadingWebcam = true;

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoadingWebcam = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error);
            this.isLoadingWebcam = false;
            alert("May the browser didn't support or there is some errors.");
          });
      } else {
        this.isLoadingWebcam = false;
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        alert("Sorry, your browser or device does not support accessing the camera. Please try using a different browser or device.");
      }
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      this.student_image = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    getGradeFee(gradeId, request) {
      axios.get("getGradeFee/" + gradeId, request)
        .then(res => {
          this.gradeFeeData = res.data[0] ?? {'annual_fee': '', 'monthly_fee': ''};
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcCity() {
      axios.get('getNrcCity')
        .then(res => {
          this.nrcno = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcNaing() {
      axios.get('getNrcNaing')
        .then(res => {
          this.naingJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcTownship() {
      axios.get('getNrcTownship')
        .then(res => {
          this.nrcNameJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getParentDetail() {
      axios.get("/parent/detail/" + this.$route.params.parentId)
        .then(response => {
          this.parentData = response.data[0];
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Parent Detail API Error", error);
        });
    },

    onProfileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = e => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.student_image = e.target.result;
            }
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg() {
      this.student_image = '';
      document.getElementById('studentImage').value = '';
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }

      if (fieldName == 'grade') {
        if (val == 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.noSectionMsg = '';
          this.isLoading = false;
          this.gradeFeeData = {'annual_fee': '', 'monthly_fee': ''};
        } else {
          this.getSection(val, request);
          this.getGradeFee(val, request);
        }
      }
      if (fieldName == "section") {
        this.section_exist = '';
      }
    },

    getSection(gradeId, request) {
      axios.get("/class/getSection/" + gradeId, request).then((response) => {
        if (response.data[0].length > 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }].concat(response.data[0]);
          this.selectedSection = this.section = 0;
          this.noSectionMsg = '';
          this.isLoading = false;
        } else {
          this.noSectionMsg = 'Please create class to show section.';
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.isLoading = false;
        }
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    // getStudentNo() {
    //   axios.get("/student/getStudentNo").then((response) => {
    //     this.c_student_no = response.data;
    //   })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         this.$store.commit('logout');
    //         window.location.replace('login');
    //       }
    //       this.isLoading = false;
    //     });
    // },

    getGradeName(campus_id = 1) {
      axios.get('getGradeName?id=' + campus_id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "All"
        }];
        this.gradeArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.studentData.$invalid ||
        this.grade == 0 ||
        this.grade == '' ||
        this.section == 0 ||
        this.section == '' ||
        this.religion == 0 ||
        this.religion == '' ) {
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        parent_id: this.parentData.id,
        name: this.studentData.name,
        student_image: this.student_image,
        // c_student_no: this.c_student_no,
        entry_no: this.entry_no,
        dob: this.studentData.dob,
        gender: this.studentData.gender,
        nationality: this.studentData.nationality,
        grade: this.grade,
        religion: this.religion,
        section: this.section,
        weight: this.studentData.weight,
        blood: this.studentData.blood,
        height: this.studentData.height,
        sibling: this.studentData.sibling,
        hobby: this.studentData.hobby,
        allergic_food: this.studentData.allergic_food,
        previous_school: this.studentData.previous_school,
        special_topic: this.studentData.special_topic,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }
      axios.post("/student/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.status == 204) {
            this.submitted = false;
            this.getSection(this.grade, {
              params: {
                campus_id: store.state.user.campus_id
              }
            });
            this.disabled = false;
            this.section_exist = "Section doesn't exist. Please choose other section.";
            return;
          }
          if (response.data.success == true) {
            this.$notification.success("Student Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "studentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          this.$notification.error("Unable to create student. Please Try Again", {
              timer: 3,
              position: "bottomCenter"
            });
          console.log("Student Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    // this.getStudentNo();
    this.getParentDetail();
    this.getGradeName();
    this.getReligion();
    this.getNrcCity();
    this.getNrcNaing();
    this.getNrcTownship();
  },
};
