import VueMonthlyPicker from 'vue-monthly-picker';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from "@/store";

export default {
  name: "paymentDetail",
  components: {
    MainLayout,
    VueMonthlyPicker,
    Loading
  },

  data() {
    return {
      paymentData: [],
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      selectedMonth: this.getDefaultMonth(),
      search_status: false,
      student_name: '',
      monthlyPdfBtn: true,
    };
  },

  watch: {
    selectedMonth(newValue) {
      if (newValue) {
        const adjustedMonth = new Date(newValue);
        adjustedMonth.setMonth(adjustedMonth.getMonth() + 1);
        this.month = adjustedMonth.toISOString().slice(0, 7);
      } else {
        this.month = null;
      }
    },
  },

  methods: {
    getDefaultMonth() {
      function formatTimeZone(date) {
        const offset = -date.getTimezoneOffset();
        const offsetHours = Math.floor(offset / 60);
        const offsetMinutes = offset % 60;
      
        const sign = offset >= 0 ? '+' : '-';
        const pad = (value) => (value < 10 ? '0' : '') + value;
      
        return `GMT${sign}${pad(offsetHours)}${pad(offsetMinutes)}`;
      }
      let currentDate = new Date();
      let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      return firstDayOfMonth.toDateString() + ' ' + formatTimeZone(firstDayOfMonth);
    },

    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      const adjustedMonth = new Date();
      const currentMonth = adjustedMonth.toISOString().slice(0, 7);
      axios.get('payment/detail/' + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        if(res.data.length == 0){
          this.$notification.error("This payment does not exist on this campus", {
              timer: 3,
              position: "bottomCenter"
          });
          this.$router.push({
              name: "paymentList"
          });
        }else{
          if (res.data.currentMonthData) {
              this.paymentData = res.data.currentMonthData;
              if (this.paymentData.length === 0) {
                this.monthlyPdfBtn = false;
              }
              this.student_name = res.data.studentName;
              this.month = currentMonth;
            } else {
              this.paymentData = res.data;
              this.student_name = res.data[0].studentName;
              this.month = currentMonth;
            }
            this.isLoading = false;
          }
        })
      .catch(error => {
        this.isLoading = false;
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Payment Detail API Error", error);
      })
    },

    reset() {
      this.month = null;
      this.selectedMonth = null;
    },

    searchInvoice() {
      this.isLoading = true;
      var request;
      if (this.month == null) {
        request = {
          params: {
            id: this.$route.params.id,
            campus_id: store.state.user.campus_id
          },
        }
      } else {
        request = {
          params: {
            month: this.month,
            id: this.$route.params.id,
            campus_id: store.state.user.campus_id
          },
        }
      }
      axios.get('payment/searchInvoice', request).then(res => {
        if (res.data.length === 0) {
          this.search_status = true;
          this.paymentData = [];
        } else {
          this.search_status = true;
          this.paymentData = res.data;
          if (this.month == null) {
            this.monthlyPdfBtn = false;
          } else {
            this.monthlyPdfBtn = true;
          }
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      })
    },

    pdfDownload(studentName, paymentId) {
      this.isLoading = true;
      var request;
      if (paymentId != null) {
        request = {
          params: {
            campus_id: this.campusId,
            payment_id: paymentId
          },
          responseType: 'blob'
        }
      } else {
        request = {
          params: {
            campus_id: this.campusId
          },
          responseType: 'blob'
        }
      }
      axios.get("/payment/pdfDownload/" + this.$route.params.id, request, {
          headers: {
            'Content-Type': 'application/pdf',
          }
        })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let date = new Date();
        const fileName = `invoice_${studentName}_${date.getFullYear()}${(date.getMonth() + 1) > 9 ? '' : '0'}${date.getMonth()+1}${(date.getDate()) > 9 ? '' : '0'}${date.getDate()}.pdf`;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.isLoading = false;
      }).catch(error => {
        if (error.response.status == 401) {
         this.$store.commit('logout');
         window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    monthlyPdfDownload(studentName) {
      this.isLoading = true;
      var request;
      if (this.search_status) {
        request = {
          params: {
            month: this.month,
            campus_id: this.campusId
          },
          responseType: 'blob'
        }
      } else {
        const adjustedMonth = new Date();
        const currentMonth = adjustedMonth.toISOString().slice(0, 7);
        request = {
          params: {
            month: currentMonth,
            campus_id: this.campusId
          },
          responseType: 'blob'
        }
      }
      axios.get("/payment/monthlyPdfDownload/" + this.$route.params.id, request, {
          headers: {
            'Content-Type': 'application/pdf',
          }
        })
      .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            if (this.search_status && this.month != null) {
              let dateObject = new Date(this.month + "-01");
              let formattedMonth = dateObject.toLocaleDateString('en-US', { month: 'short' });
              const fileName = `${formattedMonth}_invoice_${studentName}.pdf`;
              fileLink.setAttribute('download', fileName);
            } else {
              let dateObject = new Date();
              let formattedMonth = dateObject.toLocaleDateString('en-US', { month: 'short' });
              const fileName = `${formattedMonth}_invoice_${studentName}.pdf`;
              fileLink.setAttribute('download', fileName);
            }
            document.body.appendChild(fileLink);
            fileLink.click();
            this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.getPaymentMethod();
  },
};
