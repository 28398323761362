import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import Loading from "vue-loading-overlay";
import 'flatpickr/dist/flatpickr.css';
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store'
import upLoad from '@/assets/img/upload_photo3.jpg';

export default {
    name: "leaveCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            leaveData: {
                teacher_id: null,
                student_id: null,
                class_id: null,
                period: null,
                from_date: '',
                to_date: '',
                request_date: '',
                remark: '',
                status: '',
                login_id: store.state.user.id,
                img_paths: []
            },
            periodOptions: [
                { id: '0', text: 'Morning' },
                { id: '1', text: 'Evening' },
                { id: '2', text: 'Full' },
            ],
            isError: false,
            submitted: false,
            campusId: store.state.user.campus_master.campus_code,
            disabled: false,
            teacherArr: null,
            classArr: null,
            studentArr: null,
            isLoading: false,
            academicYear: [],
            noTeacherMsg: '',
            noStudentMsg: '',
            images: [],
            upLoad,
        };
    },

    validations: {
        leaveData: {
            class_id: {
                required,
            },
            student_id: {
                required,
            },
            teacher_id: {
                required,
            },
            period: {
                required,
            },
            leave_reason: {
                required,
                maxLength: maxLength(500)
            },
            from_date: {
                required
            },
            to_date: {
                required
            },
            request_date: {
                required
            },
            status: {
                required
            },
            remark: {
                maxLength: maxLength(500)
            }

        },
    },

    methods: {
        chooseFiles(id) {
            document.getElementById(id).click();
        },
        onFileChange(e) {
            var selectedFiles = e.target.files;
            if (selectedFiles[0].type.startsWith("image/")) {
                if (selectedFiles.type == "image/gif") {
                    this.$notification.error("File not supported!", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    return null;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    if (selectedFiles[i].size > 2097152) {
                        this.$notification.error("Only maximun 2Mb imges size is allowed.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        continue;
                    }
                    if (this.images.length > 4) {
                        this.$notification.error("Can only upload a maximum of five images.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    } else {
                        this.images.push(selectedFiles[i]);
                        for (let i = 0; i < this.images.length; i++) {
                            let reader = new FileReader();
                            reader.addEventListener('load', ((index) => {
                                return () => {
                                    let refName = this.images[index].name;
                                    if (this.$refs[refName] && this.$refs[refName][0]) {
                                        this.$refs[refName][0].src = reader.result;
                                    }
                                    this.images[index].data = reader.result;
                                };
                            })(i), false);

                            reader.readAsDataURL(this.images[i]);
                        }
                    }
                }
            } else {
                this.$notification.error("File not supported!", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return null;
            }
        },
        removeImage(i) {
            this.images.splice(i, 1);
        },
        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.getClass(this.academicYear.id)
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getTeacher($class_id) {
            this.teacher_id = 0;
            this.subject_id = 0;
            axios.get('/teacher/getTeacherByClass/' + $class_id)
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Teacher'
                    }];
                    this.teacherArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getStudentByClass(classId) {
            var request = {
                params: {
                    class_id: classId,
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios.get("/student/getStudentByClass", request)
                .then((res) => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student'
                    }];
                    if (res.data.length > 0) {
                        this.studentArr = $defaultSelectOption.concat(res.data);
                        this.noTeacherMsg = '';
                        this.noStudentMsg = '';
                        this.isLoading = false;
                    } else {
                        this.studentArr = $defaultSelectOption;
                        this.selectedStudent = this.student = '';
                        this.noTeacherMsg = 'No teacher for this class.'
                        this.noStudentMsg = 'No student for this class.'
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        changeClassEvent($class_id) {
            this.leaveData.teacher_id = 0;
            this.leaveData.subject_id = 0;
            this.getTeacher($class_id);
            this.getStudentByClass($class_id);

        },
        create() {
            this.submitted = true;
            if (this.leaveData.from_date && this.leaveData.to_date && new Date(this.leaveData.to_date) < new Date(this.leaveData.from_date)) {
                this.isError = true;
            } else {
                this.isError = false;
            }

            if (this.$v.$invalid || this.isError) {
                return;
            }
            this.leaveData.img_paths = this.images; 
            this.disabled = true;
            axios.post('leave/create', this.leaveData)
                .then(res => {
                    if (res.data.success == true) {
                        this.$notification.success("Leave Form Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'leaveList'
                        })
                    } else {
                        this.disabled = false;
                        this.$notification.error(res.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                }).catch((error) => {
                    this.disabled = false;
                    this.isLoading = false;
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                    }
                });
        },
    },
    mounted() {
        this.getThisAcademicYear();
    }
};
