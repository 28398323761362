import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, maxLength, minLength, email, numeric } from "vuelidate/lib/validators";
import CONFIG from "@/assets/js/config.js";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "TeacherEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      submitted: false,
      disabled: false,
      pwd_change: false,
      teacherData: [],
      selectedReligion: 0,
      religionArr: null,
      teacherTypeArr: null,
      teacherImage: "",
      nrcFrontImage: "",
      nrcBackImage: "",
      teacherOldImage: "",
      nrcFrontOldImage: "",
      nrcBackOldImage: "",
      signImage: "",
      signOldImage: "",
      teacher_type: "",
      religion: "",
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      nrc_number: "",
      nrc_only_number: "",
      nrc: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      districtList: [],
      naingList: [],
      nrcName: [],
      previewUrl: false,
      certificateFile: null,
      existingFile: null,
      emailExists: null,

      parent_chat_enable: CONFIG.TEACHER_PARENT_CHAT.ENABLE,
      parent_chat_disable: CONFIG.TEACHER_PARENT_CHAT.DISABLE,
    };
  },

  validations: {
    teacherData: {
      teacher_type_id: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(100),
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      gender: {
        required,
      },
      father_name: {
        required,
        maxLength: maxLength(50),
      },
      marital_status: {
        required,
      },
      nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      nationality: {
        required,
        maxLength: maxLength(100),
      },
      edu_background: {
        maxLength: maxLength(1000)
      },
      other_certificate: {
        maxLength: maxLength(1000)
      },
      work_experience: {
        maxLength: maxLength(1000)
      },
      join_date: {
        required,
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      // ferry_status: {
      //   required,
      // },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(1000)
      },
      permanent_address: {
        maxLength: maxLength(1000)
      },
      parent_chat_enable: {
        required
      }
    },
  },

  methods: {
    async checkDuplicateEmail() {
      try {
          const response = await axios.get("checkDuplicateEmail", {
              params: {
                  model: 'Teacher',
                  email: this.teacherData.email,
              },
          });
          this.emailExists = response.data.exists;
          if(this.emailExists) {
            this.$notification.error("Email already existed.", {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.disabled = false;
      } catch (error) {
          console.error('An error occurred:', error);
      }
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    async getNrcCity() {
      try {
        const response = await axios.get('getNrcCity')
        if (response) {
          this.nrcno = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcNaing() {
      try {
        const response = await axios.get('getNrcNaing')
        if (response) {
          this.naingJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    async getNrcTownship() {
      try {
        const response = await axios.get('getNrcTownship')
        if (response) {
          this.nrcNameJson = response.data;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      }
    },

    getTeacherType() {
      axios.get('getTeacherType')
        .then(res => {
          this.teacherTypeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    async onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.nrckey = optionText;
      } else {
        this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = await this.changeNrcName(optionValue);
    },

    changePassword() {
      this.pwd_change = true;
    },

    async changeNrcName(value) {
      return new Promise((resolve, reject) => {
        if (value) {
          this.nrcName = this.nrcNameJson[value];
          resolve(this.nrcName);
        } else {
          reject(new Error('Invalid value for NRC'));
        }
      });
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if (!optionValue) {
        this.selectedNrcNaing = "";
        this.teacherData.nrc_number = "";
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (this.nrckey !== undefined && this.selectedNrcName !== undefined && this.selectedNrcNaing !== undefined && this.teacherData.nrc_number !== "") {
        return true;
      } else if (this.nrckey == undefined && this.selectedNrcName == undefined && this.selectedNrcNaing == undefined && this.teacherData.nrc_number == "") {
        return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm( this.nrckey + "/" + this.selectedNrcName + this.selectedNrcNaing + this.teacherData.nrc_number );
    },

    async getTeacherDetail() {
      try {
        const response = await axios.get("/teacher/detail/" + this.$route.params.id, {
          params: {
            campus_id: this.$store.state.user.campus_id
          }
        })
        if (response.data) {
          if (response.data) {
            this.teacherData = response.data;
            this.teacherImage = this.teacherData.profile;
            this.nrcFrontImage = this.teacherData.nrc_front;
            this.nrcBackImage = this.teacherData.nrc_back;
            this.signImage = this.teacherData.sign_img;

            var nrcfirst = response.data.nrc_number.split('/');
            var nrcsecond = nrcfirst[1].split('(');
            var nrcthird = nrcsecond[1].split(')');
            var convertNumberService = new ConvertNumService();
            var nrckeyEng = convertNumberService.convertMm2Eng(nrcfirst[0]);
            this.nrckey = nrcfirst[0];
            this.selectedNrcName = nrcsecond[0];
            this.districtList = await this.changeNrcName(nrckeyEng);
            this.naingList = this.naingJson;
            this.selectedNrcNaing = '(' + nrcthird[0] + ')';
            this.teacherData.nrc_number = nrcthird[1];
            // await this.getFerryStatusById(this.teacherData.id);

            this.teacherData.religion != null ? (this.selectedReligion = this.religion = this.teacherData.religion) : null;

            if (this.teacherData.certificateInfo != null) {
              this.previewUrl = true;
              this.existingFile = this.teacherData.certificateInfo.file_name;
              this.certificateFile = this.teacherData.certificateInfo.file_name;
            } else {
              this.previewUrl = false;
            }

            var teacherTypeExists = false;
            Object.values(this.teacherTypeArr).forEach(value => {
              if (value['id'] == this.teacherData.teacher_type_id) {

                teacherTypeExists = true;
              }
            });
            if (teacherTypeExists == false) {
              this.teacherData.teacher_type_id = "";
            }
          }
        }
        this.isLoading = false;
      } catch (error) {
        if (error.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Teacher Detail API Error", error);
      }
    },

    // getFerryStatusById(id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('getFerryStatusById/' + id,{
    //       params: {
    //         user_type: 'teacher'
    //       }
    //     })
    //       .then(res => {
    //         this.teacherData = Object.assign({}, this.teacherData, { ferry_status: res.data.ferry_status });

    //         this.isLoading = false;
    //         resolve();
    //       })
    //       .catch(error => {
    //         if (error.response && error.response.status === 401) {
    //           this.$store.commit('logout');
    //           window.location.replace('login');
    //         }
    //         this.isLoading = false;
    //         reject(error);
    //       });
    //   });
    // },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          // validate for sign image
          if (id == 'signImage' && inputfile.type !== "image/png") {
            this.$notification.error("Only PNG file type allowed!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      this.existingFile = null;
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;

      const allowedTypes = ['.zip'];
      const fileType = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')).toLowerCase();

      if (selectedFile.size > 10485760) {
        this.$notification.error("Zip File too large", {
          timer: 3,
          position: "bottomCenter",
        });
        this.$refs.fileInput.value = '';
      } else if (allowedTypes.includes(fileType)) {
        this.certificateFile = selectedFile;
        this.previewUrl = URL.createObjectURL(selectedFile);
      } else {
        this.$notification.error("File not supported!", {
          timer: 3,
          position: "bottomCenter",
        });
        this.$refs.fileInput.value = '';
      }
    },

    deleteFile() {
      this.previewUrl = false;
      this.existingFile = null;
      this.certificateFile = null;
    },

    async confirmUpdate() {
      this.submitted = true;
      if (this.teacherData.mail) {
        await this.checkDuplicateEmail();
      }
      if (this.$v.$invalid || this.emailExists) {
        return;
      } else if (this.pwd_change) {
        if(this.teacherData.password == '' || this.teacherData.password == undefined || this.teacherData.confirm_password == '' || this.teacherData.confirm_password == undefined) {
          return;
        } else if (this.teacherData.password.length < 8 || this.teacherData.password.length >20 || this.teacherData.confirm_password.length < 8 || this.teacherData.confirm_password.length >20) {
          return;
        } else if(this.teacherData.password != this.teacherData.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }
      this.update();
    },

    update() {
      this.isLoading = true;
      this.disabled = true;
      const formData = new FormData();
      formData.append('name', this.teacherData.name == null ? '' : this.teacherData.name);
      formData.append('teacher_type_id', this.teacherData.teacher_type_id == null ? '' : this.teacherData.teacher_type_id);
      formData.append('profileImage', this.teacherImage == null ? '' : this.teacherImage);
      formData.append('nrcFrontImage', this.nrcFrontImage == null ? '' : this.nrcFrontImage);
      formData.append('nrcBackImage', this.nrcBackImage == null ? '' : this.nrcBackImage);
      formData.append('certificate_file', this.certificateFile == null ? '' : this.certificateFile);
      formData.append('signImage', this.signImage == null ? '' : this.signImage);

      formData.append('profileOldImageName', this.teacherData.profile_path == null ? '' : this.teacherData.profile_path);
      formData.append('nrcFrontOldImageName', this.teacherData.nrc_front_path == null ? '' : this.teacherData.nrc_front_path);
      formData.append('nrcBackOldImageName', this.teacherData.nrc_back_path == null ? '' : this.teacherData.nrc_back_path);
      formData.append('oldCertifiFile', this.teacherData.certificateInfo == null ? '' : this.teacherData.certificateInfo.file_name);
      formData.append('signOldImageName', this.teacherData.sign_img_path == null ? '' : this.teacherData.sign_img_path);

      formData.append('religion', this.religion == null ? '' : this.religion);
      formData.append('dob', this.teacherData.dob == null ? '' : this.teacherData.dob);
      formData.append('gender', this.teacherData.gender == null ? '' : this.teacherData.gender);
      formData.append('father_name', this.teacherData.father_name == null ? '' : this.teacherData.father_name);
      formData.append('marital_status', this.teacherData.marital_status == null ? '' : this.teacherData.marital_status);
      formData.append('nrc_number', this.mergeNRC());
      formData.append('nationality', this.teacherData.nationality == null ? '' : this.teacherData.nationality);
      formData.append('edu_background', this.teacherData.edu_background == null ? '' : this.teacherData.edu_background);
      formData.append('other_certificate', this.teacherData.other_certificate == null ? '' : this.teacherData.other_certificate);
      formData.append('work_experience', this.teacherData.work_experience == null ? '' : this.teacherData.work_experience);
      formData.append('join_date', this.teacherData.join_date == null ? '' : this.teacherData.join_date);
      formData.append('password', this.teacherData.password == null ? '' : this.teacherData.password);
      formData.append('email', this.teacherData.email == null ? '' : this.teacherData.email);
      // formData.append('ferry_status', this.teacherData.ferry_status == null ? '' : this.teacherData.ferry_status);
      formData.append('phone1', this.teacherData.phone1 == null ? '' : this.teacherData.phone1);
      formData.append('phone2', this.teacherData.phone2 == null ? '' : this.teacherData.phone2);
      formData.append('contact_address', this.teacherData.contact_address == null ? '' : this.teacherData.contact_address);
      formData.append('permanent_address', this.teacherData.permanent_address == null ? '' : this.teacherData.permanent_address);
      formData.append('campus_id', this.$store.state.user.campus_id);
      formData.append('login_id', this.$store.state.user.id);
      formData.append('certificate_id', this.teacherData.certificateInfo == null ? '' : this.teacherData.certificateInfo.id);
      formData.append('old_url', this.teacherData.certificateInfo == null ? '' : this.teacherData.certificateInfo.url);
      formData.append('parent_chat_enable', this.teacherData.parent_chat_enable == null ? CONFIG.TEACHER_PARENT_CHAT.DISABLE : this.teacherData.parent_chat_enable);

      axios
        .post("/teacher/update/" + this.$route.params.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$notification.success("Teacher Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.$router.push({ name: "teacherList" });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
          console.log("Teacher Update API Error", error.errors);
        });
    },

    back() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.isLoading = true;
    this.getReligion();
    await this.getNrcCity();
    await this.getNrcNaing();
    await this.getNrcTownship();
    this.getTeacherType();
    this.getTeacherDetail();
  },
};
