import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import CONFIG from "@/assets/js/config.js";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, maxLength } from "vuelidate/lib/validators";
import store from "@/store";
import upLoad from '@/assets/img/upload_photo3.jpg';

export default {
    name: "CourseEdit",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        Loading,
    },

    data() {
        return {
            academicYearArr: null,
            classArr: null,
            subjectArr: null,
            teacherArr: null,
            courseData: [],
            isLoading: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            ssImage: '',
            upLoad,
            disabled: false,
            course_ss: '',
            submitted: false,
            campus_id: '',
            acd_year_id: '',
            class_id: '',
            teacher_id: '',
            student_id: '',
            images: [],
            existingImages: [],
            noClassMsg: '',
        };
    },

    validations: {
        courseData: {
            acd_year_id: {
                required
            },
            class_id: {
                required
            },
            teacher_id: {
                required
            },
            subject_id: {
                required
            },
            unit: {
                required,
                maxLength: maxLength(500)
            },
            lesson: {
                required,
            },
            send_date: {
                required
            }
        },
    },

    methods: {

        chooseFiles(id) {
            document.getElementById(id).click();
        },
        onFileChange(e) {
            var selectedFiles = e.target.files;
            if (selectedFiles[0].type.startsWith("image/")) {
                if (selectedFiles.type == "image/gif") {
                    this.$notification.error("File not supported!", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    return null;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    if (selectedFiles[i].size > 2097152) {
                        this.$notification.error("Only maximun 2Mb imges size is allowed.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        continue;
                    }
                    let total_img_count = this.images.length + this.existingImages.length;
                    if (total_img_count > 4) {
                        this.$notification.error("Can only upload a maximum of five images.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    } else {
                        this.images.push(selectedFiles[i]);
                        for (let i = 0; i < this.images.length; i++) {
                            let reader = new FileReader();
                            reader.addEventListener('load', ((index) => {
                                return () => {
                                    let refName = this.images[index].name;
                                    if (this.$refs[refName] && this.$refs[refName][0]) {
                                        this.$refs[refName][0].src = reader.result;
                                    }
                                    this.images[index].data = reader.result;
                                };
                            })(i), false);

                            reader.readAsDataURL(this.images[i]);
                        }
                    }
                }
            } else {
                this.$notification.error("File not supported!", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return null;
            }


        },
        removeImage(i) {
            this.images.splice(i, 1);
        },
        removeExistingImage(id) {
            const indexInExistingImages = this.existingImages.findIndex(img => img.id === id);
            this.existingImages.splice(indexInExistingImages, 1);
        },
        getDetail() {
            axios.get('course/detail/' + this.$route.params.id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
            .then((res) => {
                if (res.data) {
                    this.courseData = res.data;
                    this.class_id = this.courseData.class_id;
                    this.teacher_id = this.courseData.teacher_id;
                    this.subject_id = this.courseData.subject_id;
                    this.acd_year_id = this.courseData.acd_year_id;
                   if(this.courseData.img_paths){
                       this.existingImages = this.courseData.img_paths
                    }
                    this.getTeacher(res.data.class_id);
                    this.getSubject(res.data.teacher_id, res.data.class_id);
                    this.getClass(res.data.acd_year_id);
                }
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getCurrentPeriodList() {
            axios.get('/period/getCurrentPeriodList')
            .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select Academic Year'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data);
                this.isLoading = false;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },
        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    if (this.classArr.length >= 1) {
                        this.noClassMsg = '';
                    } else {
                        this.noClassMsg = 'There is no class to show.';
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getTeacher($class_id) {
            this.teacher_id = 0;
            this.subject_id = 0;
            axios.get('/teacher/getTeacherByClass/' + $class_id)
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Teacher'
                    }];
                    this.teacherArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getSubject($teacher_id, $class_id) {
            axios.get('/teacher/getSubjectByTeacher/' + $teacher_id + '/' + $class_id)
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Subject'
                    }];
                    this.subjectArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        update() {
            this.submitted = true;
            if (this.$v.$invalid || this.courseData.class_id == 0 || this.courseData.subject_id == 0 || this.courseData.subject_id == '') {
                return;
            }
            this.courseData.img_paths = this.existingImages;
            this.courseData.new_images = this.images;
            this.courseData.updated_id = this.$store.state.user.id;
            this.courseData.campus_id = this.$store.state.user.campus_id;
            this.disabled = true;
            axios.post(`course/update/${this.courseData.id}`, this.courseData)
                .then(res => {
                    if (res.data) {
                        this.$notification.success("Course Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "courseList"
                        });
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        changeClassEvent($class_id) {
            this.courseData.teacher_id = 0;
            this.courseData.subject_id = 0;
            this.getTeacher($class_id);
        },
        changeTeacherEvent($teacher_id, $class_id) {
            this.courseData.subject_id = 0;
            this.getSubject($teacher_id, $class_id);
        },
        changeAcademicYearEvent($acd_year_id) {
            this.courseData.class_id = 0;
            this.courseData.teacher_id = 0;
            this.courseData.subject_id = 0;
            this.getClass($acd_year_id);
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        this.getCurrentPeriodList();
    },
}
