import { render, staticRenderFns } from "./StudentPass.vue?vue&type=template&id=cc4b8d84"
import script from "../../controllers/student/student-pass.js?vue&type=script&lang=js&external"
export * from "../../controllers/student/student-pass.js?vue&type=script&lang=js&external"
import style0 from "./StudentPass.vue?vue&type=style&index=0&id=cc4b8d84&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports