import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";

export default {
    name: "SubjectList",
    components: {
        MainLayout,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            campusGradeArr: null,
            selectedCampusGrade: 0,
            id: 0,
            campus_grade_id: 0,
            gradeArr: [],
            subjectArr: [],
            subjectMasterData: {},
            currentDate: null
        };
    },

    methods: {
        getCampusGradeName(campus_id = 1) {
            axios.get('getAcademicYearCampusGradeName?id=' + campus_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "All"
                }];
                this.campusGradeArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
            if (fieldName == "campus_grade_id") {
                this.subjectArr = [];
                this.id = 0;
                this.getSubjectBygradeId(val);
            }
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        reset() {
            this.subjectArr = [];
            this.id = 0;
            this.selectedCampusGrade = 0;
            this.campus_grade_id = 0;
        },

        getAllSubject(page = 1) {
            axios.get('subject/list?page=' + page, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then((response) => {
                this.subjectMasterData = response.data;
                this.isLoading = false;
                this.currentDate = new Date().toISOString().slice(0, 10);
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getSubjectBygradeId(campus_grade_id) {
            axios.get("/subject/subjectByGradeId/" + campus_grade_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
                .then((response) => {
                    let $defaultSelectOption = [{
                        'id': '0',
                        'text': 'Select Subject'
                    }
                    ];
                    this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },

        showCreate() {
            this.$router.push({
                path: "/subject/create"
            });
        },

        showDetail(campus_grade_id) {
            this.$router.push({
                name: "subjectDetail",
                params: {
                    campusGradeId: campus_grade_id
                }
            });
        },

        searchSubject(page = 1) {
            this.isLoading = true;
            axios.get('subject/search?page=' + page, {
                params: {
                    campus_grade_id: this.campus_grade_id,
                    id: this.id,
                    campus_id: store.state.user.campus_id
                }
            })
                .then((res) => {
                    this.subjectMasterData = res.data;
                    this.isLoading = false;
                    this.search_status = true;
                    this.currentDate = new Date().toISOString().slice(0, 10);
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showEdit(campus_grade_id) {
            this.$router.push({
                name: "subjectEdit",
                params: {
                    campusGradeId: campus_grade_id
                }
            });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/subject/excelDownload', {
                params: {
                    campus_grade_id: this.campus_grade_id,
                    id: this.id,
                    campus_id: store.state.user.campus_id
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `subject_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },
    mounted() {
        this.isLoading = true;
        this.getCampusGradeName();
        this.getAllSubject();
    },
};
