import { render, staticRenderFns } from "./StudentFail.vue?vue&type=template&id=8480cdc6"
import script from "../../controllers/student/student-fail.js?vue&type=script&lang=js&external"
export * from "../../controllers/student/student-fail.js?vue&type=script&lang=js&external"
import style0 from "./StudentFail.vue?vue&type=style&index=0&id=8480cdc6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports