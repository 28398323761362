import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";

export default {
    name: "GradeFeeList",
    components: {
        MainLayout,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            campusGradeArr: null,
            selectedGrade: 0,
            selectedCampusGrade: 0,
            searchData: [],
            gradeFeeMasterData: {},
        };
    },

    methods: {
        getCampusGradeName(campus_id = 1) {
            axios.get('getAcademicYearCampusGradeName?id=' + campus_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then(res => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "All"
                }];
                this.campusGradeArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        reset() {
            this.selectedCampusGrade = 0;
            this.searchData = [];
        },

        searchGradeFee(page = 1) {
            this.isLoading = true;
            axios.get('gradeFee/search?page=' + page, {
                params: {
                    campus_grade_id: this.selectedCampusGrade,
                    annual_fee: this.searchData.annual_fee,
                    monthly_fee: this.searchData.monthly_fee,
                    campus_id: store.state.user.campus_id,
                }
            })
                .then(res => {
                    if (res.data) {
                        this.gradeFeeMasterData = res.data;
                    }
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        getAllGradeFee(page = 1) {
            axios.get('gradeFee/list?page=' + page, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }).then((response) => {
                this.gradeFeeMasterData = response.data;
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showCreate() {
            this.$router.push({
                path: "/gradeFee/create"
            });
        },

        showEdit(gradeFeeId) {
            this.$router.push({
                name: "gradeFeeEdit",
                params: {
                    id: gradeFeeId
                }
            });
        },

        showDetail(gradeFeeId) {
            this.$router.push({
                name: "gradeFeeDetail",
                params: {
                    id: gradeFeeId
                }
            });
        },

        confirmDelete(gradeFeeId) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteGradeFee(gradeFeeId);
            } else {
                console.log("no");
            }
        },

        deleteGradeFee(gradeFeeId) {
            this.isLoading = true;
            axios.delete("/gradeFee/delete/" + gradeFeeId)
                .then(response => {
                    if (response.data.success == true) {
                        this.getAllGradeFee();
                    } else {
                        this.$notification.error("Grade fee data is already deleted.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.getAllGradeFee();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grade Fee Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/gradeFee/excelDownload', {
                params: {
                    campus_grade_id: this.selectedCampusGrade,
                    annual_fee: this.searchData.annual_fee,
                    monthly_fee: this.searchData.monthly_fee,
                    campus_id: store.state.user.campus_id
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `grade_fee_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getCampusGradeName();
        this.getAllGradeFee();
    },
};
