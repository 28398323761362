import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=4aad486c"
import script from "../../controllers/student/student-list.js?vue&type=script&lang=js&external"
export * from "../../controllers/student/student-list.js?vue&type=script&lang=js&external"
import style0 from "./StudentList.vue?vue&type=style&index=0&id=4aad486c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports